import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'
import App from './PollockApp.vue'

import { readContract, prepareWriteContract, writeContract, getNetwork, switchNetwork, waitForTransaction, signMessage, sendTransaction } from '@wagmi/core'
import { wagmiSetup, walletEventSetup } from '@/store/wallet'

import MarketplaceClient from '@Pass-It-Down/marketplace-client'
import routerInit from './router'
import storeInit from './store'
import authInit from './store/modules/auth'
import dropsInit from './store/modules/drops'
import uiInit from './store/modules/ui'
import shippingInit from './store/modules/shipping'
import claimInit from './store/modules/claim'
import orderInit from './store/modules/order'
import itemsInit from './store/modules/items'
import navInit from './store/modules/nav'


const { web3modal, chainId } = wagmiSetup()

const url = process.env.VUE_APP_SERVER_URL
const _log = Sentry.captureException

const clientParams = {
  url, _log,
  chainId, readContract, prepareWriteContract, writeContract, getNetwork, switchNetwork, waitForTransaction, signMessage, sendTransaction
}
const client = new MarketplaceClient(clientParams)

const auth = authInit(client, web3modal)
const drops = dropsInit(client)
const ui = uiInit(client)
const shipping = shippingInit(client)
const order = orderInit(client)
const claim = claimInit(client)
const items = itemsInit()
const nav = navInit(client)


const app = createApp(App)
const store = storeInit({ auth, drops, nav, ui, shipping, order, claim, items })
const router = routerInit(store)

Sentry.init({ app, dsn: process.env.VUE_APP_SENTRY_DSN })

app.use(store).use(router)
router.isReady().then(() => {
  app.mount('#app')
})

walletEventSetup(store)
