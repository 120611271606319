<template>
  <Header />
  <router-view />
  <Overlay v-if="isOverlayShown" />
  <Footer />
</template>

<script>
import Header from '@/components/pollock/header/Header.vue'
import Overlay from './components/pollock/Overlay.vue'
import Footer from './components/pollock/Footer.vue'
import { mapState } from 'vuex'


export default {
  components: {
    Header,
    Overlay,
    Footer
  },
  computed: {
    ...mapState({
      isOverlayShown: state => state.ui.isOverlayShown
    })
  },
  created() {
    document.addEventListener('SWUpdated', this.showRefresh)
  }
}
</script>

<style lang="scss">
@import '@/styles/pollock-main';
</style>
