// Views
const PollockListing = () => import(/* webpackChunkName: "PollockListing" */ '@/views/PollockListing.vue')
const PollockItem = () => import(/* webpackChunkName: "PollockItem" */ '@/views/PollockItem.vue')
const CollectionDetail = () => import(/* webpackChunkName: "CollectionDetail" */ '@/views/CollectionDetail.vue')
const PurchaseReveal = () => import(/* webpackChunkName: "PurchaseReveal" */ '@/views/PurchaseReveal.vue')
const ShippingInfo = () => import(/* webpackChunkName: "ShippingInfo" */ '@/views/ShippingInfo.vue')
const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue')
const Checkout = () => import(/* webpackChunkName: "Checkout" */ '@/views/Checkout.vue')
const CheckoutComplete = () => import(/* webpackChunkName: "Checkout" */ '@/views/CheckoutComplete.vue')
const Claims = () => import(/* webpackChunkName: "Claims" */ '@/views/Claims.vue')
const ClaimsCheck = () => import(/* webpackChunkName: "Claims" */ '@/views/ClaimsCheck.vue')

// Components

// Routes
export const routes = [
    {
        path: '/',
        component: NotFound,
        beforeEnter: () => {
            window.location.href = 'https://iconicmoments.co';
        }
    },
    {
        name: 'partnerListing',
        path: '/:partner_slug/collections',
        component: PollockListing,
        alias: '/collections/:partner_slug'
    },
    {
        name: 'pollockitem',
        path: '/:partner_slug/details/:slug',
        component: PollockItem,
        alias: '/details/:slug'
    },
    {
        name: 'collectiondetail',
        path: '/:partner_slug/collections/:drop_slug',
        component: CollectionDetail,
        alias: '/collections/:partner_slug/:drop_slug'
    },
    {
        name: 'purchase',
        path: '/:partner_slug/collections/:slug/purchase/:txhash',
        component: PurchaseReveal
    },
    {
      name: 'checkout',
      path: '/:partner_slug/checkout/:slug',
      component: Checkout
    },
    {
      name: 'checkoutcomplete',
      path: '/:partner_slug/checkout/:slug/complete',
      component: CheckoutComplete
    },
    {
        name: 'shipping',
        path: '/:partner_slug/shipping-info/:txhash',
        component: ShippingInfo
    },
    {
        name: 'claims',
        path: '/:partner_slug/claims',
        component: Claims
    },
    {
        name: 'claimscheck',
        path: '/:partner_slug/claims/check',
        component: ClaimsCheck
    },
    {
        name: 'notfound',
        path: '/:pathMatch(.*)*',
        component: NotFound
    }
]
