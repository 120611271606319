<template>
  <div class="copyright">
    <p>Copyright &copy; 2024 Iconic Moments, LLC.
      <span class="underline"><a href="https://iconicmoments.co/iconic-licensing-agreement" target="_blank">All Rights
        Reserved.</a></span>
      <span class="terms-of-service"> By using this site, you signify that you agree to be bound by our
        <span class="underline"><a href="https://iconicmoments.co/terms-of-service" target="_blank">Universal Terms of
          Service.</a></span>
      </span>
    </p>
  </div>
</template>
<style scoped>
.copyright {
  font-size: 0.5rem;
  color: #000000;
  margin-top: 7rem;
  font-family: 'Poppins', sans-serif;
}

.terms-of-service {
  padding-left: 50px;
}

.underline {
  text-decoration: underline;
}

a {
  text-transform: capitalize;
  text-decoration: none;
  font-weight: bold;
  color: black;
}

@media (min-width: 431px) and (max-width: 1024px) {
  .copyright {
    font-size: 0.3em;
  }

  .terms-of-service {
    padding-left: 35px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .copyright {
    font-size: 0.5em;
  }
}

@media (max-width: 430px) {
  .copyright {
    text-align: center;
    margin-top: 2rem;
  }

  .terms-of-service {
    padding-left: 0;
  }
}

@media (min-width: 1025px) {
  .copyright {
    font-size: 0.7vw;
  }
}
</style>