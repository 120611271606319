const openseaBaseNftUri = process.env.VUE_APP_OPENSEA_NFT_URI
const discordUri = 'https://discord.gg/iconicmoments'
const instagramUri = 'https://www.instagram.com/iconicmoments_nft'
const twitterUri = 'https://twitter.com/Iconic_NFT'

function createElementsFromHTML(htmlString) {
  var div = document.createElement('div')
  div.innerHTML = htmlString.trim()

  return div.children
}

function priceToDollars(price) {
  if(!price) {
    return ''
  }
  price = parseInt(price) / 100
  const formatting_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
 }
 const dollarFormatter = new Intl.NumberFormat('en-US', formatting_options )
  return dollarFormatter.format(price)
}

function trimEmail(email) {
  if(!email) return ''

  if(email.length > 27) {
    return `${email.slice(0, 24)}...`
  }

  return email
}

function getBackgroundStyle(obj, prop) {
  const bgImage = obj && obj[prop];
  if(bgImage) {
    return { backgroundImage: `url(${bgImage})` }
  }
  return { }
}

const faqs =
  [{
    question: 'What is an NFT?',
    answer: 'NFT stands for “non-fungible token.” An NFT is a digital asset that represents real-world objects like art, music, in-game items and videos. They are secured by the Ethereum blockchain (ERC-721). This way, no one can modify the record of ownership or copy/paste a new NFT into existence.',
  }, {
    question: 'When can I buy an NFT?',
    answer: 'For updated drop information including drop date, drop pricing and other information, please follow us on Twitter and join our Discord.'
  }, {
    question: "What is an 'Allowlist' ?",
    answer: 'An allowlist is a list of individuals who will have early access to purchase an NFT before the general public can. Being on our allowlist guarantees you will be able to purchase one of our limited-edition NFTs.'
  }, {
    question: 'How do I get on the allowlist?',
    answer: 'Please follow us on Twitter and join our Discord to be notified of allowlist opportunities.'
  }, {
    question: 'What are gas fees?',
    answer: 'A gas fee is something all users must pay in order to perform any function on the Ethereum blockchain. When you purchase an NFT you must pay a gas fee in order to process your NFT purchase.'
  }]

export {
  createElementsFromHTML,
  priceToDollars,
  trimEmail,
  getBackgroundStyle,
  openseaBaseNftUri,
  discordUri,
  instagramUri,
  twitterUri,
  faqs
}
