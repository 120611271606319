export default function(client) {
  const walletClient = client.wallet()
  const shippingClient = client.shipping()

  const state = {
    nonce: null,
    order: null
  }

  const actions = {
    async getNonce({ commit }, address) {
      if(!address) {
        return
      }
      
      const nonce = await shippingClient.getNonce(address)
      commit('setNonce', nonce)
      return nonce
    },
    async requestSignature({ state }) {
      const baseMessage = 'Welcome to Iconic Moments! Your nonce is '
      const unsignedMessage = baseMessage + state.nonce
      return await walletClient.signedMessage(unsignedMessage)
    },
    async submitInfo({ commit }, info) {
      const order = await shippingClient.save(info)
      commit('setOrder', order)
      return order
    }
  }

  const mutations = {
    setNonce(state, nonce) {
      state.nonce = nonce
    },
    setOrder(state, order) {
      state.order = order
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
  }
}
