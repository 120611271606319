import { allowListIsNotActive } from '../../dropHelpers.js'

export default function(client, web3modal) {
  const proofClient = client.proof()

  const state = {
    account: {
      address: "",
      isConnected: false
    },
    proof: []
  }

  const actions = {
    async connectWallet() {
      const options = "Account" | "ConnectWallet" | "Help" | "SelectNetwork"
      await web3modal.openModal(options)
    },
    async getProofByDrop({commit, state, rootState}) {
      const drop = rootState.drops.dropDetail

      if(!state.account.isConnected || allowListIsNotActive(drop)) {
        commit('setProof', [])
        return
      }

      const {proof} = await proofClient.getByAccountAndDropId(state.account.address, drop.id)
      commit('setProof', proof)
    },
    async setAccount({ commit }, account) {
      commit('setAccountState', account)
    }
  }

  const mutations = {
    setAccountState(state, account) {
      state.account.address = account.address
      state.account.isConnected = account.isConnected
    },
    setProof(state, proof) {
      state.proof = proof
    }
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations
  }
}
