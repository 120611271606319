export default function(client) {
  const dropClient = client.drop()
  const walletClient = client.wallet()
  const partnerClient = client.partner()

  const state = {
    drops: [],
    selectedDropImage: "",
    featuredDrops: [],
    dropDetail: {},
    purchaseState: 'none',
    purchaseError: '',
    tokenId: 0,
    contractAbi: [],
    partnerDrops: []
  }

  const handlePurchaseResult = function(commit, error, txHash) {
    const purchaseState = txHash ? 'pending' : 'none'
    commit('setPurchaseState', purchaseState)

    if(error) commit('setPurchaseError', error)

    return { error, txHash }
  }

  const actions = {
    async getDrops({ commit }) {
      const drops = await dropClient.getDrops()
      commit('setDrops', drops)
    },

    async getFeaturedDrops({ commit, state }) {
      const drops = await dropClient.getFeaturedDrops()
      commit('setFeaturedDrops', drops)
    },

    async getDropFromDrops({ commit, state }, dropTitle) {
      let drops = state.drops
      if(!drops.length) {
        drops = await dropClient.getDrops()
        commit('setDrops', drops)
      }

      const thisDrop = drops.find(d => dropTitle === d.title)
      return thisDrop
    },

    async loadDropDetail({ commit }, slug) {
      if(state.dropDetail.slug == slug) {
        return state.dropDetail
      }

      commit('setDropDetail', {})
      const dropDetail = await dropClient.getDropDetail(slug)
      if(dropDetail.wei_price) {
        dropDetail.ethPrice = await walletClient.formatWei(dropDetail.wei_price)
      }

      commit('setDropDetail', dropDetail)
      commit('setSelectedDropImage', dropDetail.roadmap_image_1)
      return dropDetail
    },

    async getDropDetail() {
      return state.dropDetail
    },

    async getDropAbi({ commit, state }) {
      const dropDetail = state.dropDetail
      const {contract_abi} = await dropClient.getAbiById(dropDetail.id)
      commit('setContractAbi', contract_abi)
    },

    async allowedPurchase({ commit, rootState }, numberToMint) {
      commit('setPurchaseError', '')
      const proof = rootState.auth.proof
      const { error, hash } = await walletClient.allowedPurchase(state.dropDetail, state.contractAbi, proof, numberToMint)
      return handlePurchaseResult(commit, error, hash)
    },

    async openPurchase({ commit }, numberToMint) {
      commit('setPurchaseError', '')
      const { error, hash } = await walletClient.openPurchase(state.dropDetail, state.contractAbi, numberToMint)
      return handlePurchaseResult(commit, error, hash)
    },

    async checkTransaction({commit}, txHash) {
      const {status, tokenId} = await walletClient.getFinalTransactionStatus(txHash)
      const purchaseState = status ? 'success' : 'fail'

      commit('setPurchaseState', purchaseState)
      commit('setTokenId', tokenId)

      return status
    },

    async selectDropImage({commit}, dropImage) {
      commit('setSelectedDropImage', dropImage)
    },

    async getPartnerDrops({ commit }, partner_slug) {
      const drops = await partnerClient.getDrops(partner_slug)
      commit('setPartnerDrops', drops)
      return drops
    }
  }

  const mutations = {
    setDrops(state, drops) {
      state.drops = drops
    },
    setFeaturedDrops(state, featuredDrops) {
      state.featuredDrops = featuredDrops
    },
    setDropDetail(state, dropDetail) {
      state.dropDetail = dropDetail
    },
    setProof(state, dropProof) {
      state.dropProof = dropProof
    },
    setPurchaseState(state, purchaseState) {
      state.purchaseState = purchaseState
    },
    setPurchaseError(state, purchaseError){
      state.purchaseError = purchaseError
    },
    setTokenId(state, tokenId) {
      state.tokenId = tokenId
    },
    setContractAbi(state, contractAbi) {
      state.contractAbi = contractAbi
    },
    setSelectedDropImage(state, dropImage) {
      state.selectedDropImage = dropImage
    },
    setPartnerDrops(state, drops) {
      state.partnerDrops = drops
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
  }
}
