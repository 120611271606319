<template>
  <li v-if="target"><a :href="link" :target="target">{{ name }}</a></li>
  <li v-else><a :href="link">{{ name }}</a></li>
</template>

<script>
export default {
  name: 'NavLink',
  props: ['link', 'target', 'name']
}
</script>

<style scoped lang="scss">
li {
  padding-left: 2em;
  padding-right: 2em;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: black;
}

.sidenav a {
  color: #818181 !important;
}

@media (max-width: 425px) {
  a {
    padding: 8px 0;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

}
</style>
