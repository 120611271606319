import {
  configureChains,
  createConfig,
  watchAccount,
  watchNetwork
} from '@wagmi/core'
import { mainnet, sepolia }  from '@wagmi/core/chains'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/html'
 
const projectId = process.env.VUE_APP_WALLETCONNECT_PROJECT_ID
const chains = [mainnet, sepolia]
const chainId = parseInt(process.env.VUE_APP_CHAIN_ID)

const wagmiSetup = () => {
  const { publicClient } = configureChains(
    chains,
    [w3mProvider({ projectId })],
   )

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
  })

  const ethereumClient = new EthereumClient(wagmiConfig, chains)
  const web3modal = new Web3Modal({ projectId }, ethereumClient)

  return { web3modal, chainId }
}

const walletEventSetup = (store) => {
  watchAccount((account) => {
    store.dispatch('auth/setAccount', account)
  })

  watchNetwork(network => {
    const currentChainId = network.chain?.id
    store.dispatch('ui/checkShowChain', currentChainId)
  })
}

export {
  wagmiSetup,
  walletEventSetup
}
