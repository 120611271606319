<template>
  <div class="overlay" />
  <div class="lds-ring-container">
    <div class="lds-ring"><div /><div /><div /><div /></div>
    <div class="spinner-box">
      <div class="spinner-iconic">I</div>
    </div>
    <div class="mint-message">Please do not close or refresh this page until the transaction is complete.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: mapState({
    showOverlay: state => state.ui.showOverlay
  }),
}
</script>

<style lang="scss" scoped>
$spinner-size: 230px;
$spinner-size-inner: 190px;
$box-size: 90px;

.spinner-box {
  border: 4px solid var(--color-primary-one);
  position: fixed;
  width: $box-size;
  height: $box-size;
  top: 50%;
  left: 50%;
  margin-top: -($box-size/2)-15px;
  margin-left: -($box-size/2)-15px;
  color: var(--color-primary-one);
  line-height: $box-size;
}
.spinner-iconic {
  padding-left: ($box-size/2)-12px;
  font-size: 40px;
  font-family: $font-family-secondary;
}
.mint-message {
  text-transform: uppercase;
  font-family: $font-family-secondary;
  font-size: .9em;
  color: var(--color-primary-one);
}

.overlay {
  z-index: 1000;
  background-color: black;
  position: fixed;
  opacity: .2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; 
}
.lds-ring-container {
  position: fixed;
  width: $spinner-size;
  height: $spinner-size;
  top: 50%;
  left: 50%;
  margin-top: -$spinner-size/2;
  margin-left: -$spinner-size/2;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: $spinner-size;
  height: $spinner-size;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: $spinner-size-inner;
  height: $spinner-size-inner;
  margin: 8px;
  border: 8px solid var(--color-primary-one);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--color-primary-one) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
