export default function(client) {
  const walletClient = client.wallet()

  const state = {
    showChainWarning: false,
    isOverlayShown: false,
    dataLoaded: false
  }

  const actions = {
    async dataLoaded({ commit }) {
      commit('setDataLoaded', true)
    },
    checkShowChain({commit}, selectedChainId) {
      const isCorrectChain = walletClient.isCorrectChain(selectedChainId)
      commit('setShowChainWarning', !isCorrectChain)
    },
    async requestSwitchChain({commit}) {
      const chainSwitched = await walletClient.requestSwitchChain()
      commit('setShowChainWarning', !chainSwitched)
    },
    async showOverlay({commit}, isOverlayShown) {
      commit('setShowOverlay', isOverlayShown)
    }
  }

  const mutations = {
    setShowChainWarning(state, showChainWarning) {
      state.showChainWarning = showChainWarning
    },
    setDataLoaded(state, dataLoaded) {
      state.dataLoaded = dataLoaded
    },
    setShowOverlay(state, isOverlayShown) {
      state.isOverlayShown = isOverlayShown
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations, 
    getters,
  }
}
