<template>
  <footer>
    <div class="wrapper">
      <div class="logo-section">
        <div class="icon">
          <img :src="siteDetail.logo">
        </div>
        <p class="text-small">{{ siteDetail.tag_line }}</p>
      </div>
      <div class="align-right">
        <div class="align-right_links">
          <ul class="link-row">
            <li v-for="(item, key) in footerLinks" :key="key">
              <nav-link :link="item.full_url" :name="item.title" :target="item.target" />
            </li>
          </ul>
          <copyright />
        </div>
      </div>
      <div class="mobile">
        <copyright />
      </div>
    </div>
  </footer>
</template>
<script>
import NavLink from './header/NavLink.vue'
import Copyright from './Copyright.vue'
import { mapState } from 'vuex'


export default {
  components: { NavLink, Copyright },
  computed: {
     ...mapState({
       siteDetail: state => state.nav.siteDetail
     }),
     footerLinks() {
      return this.siteDetail.footerLinks
    },
  },
    async created() {
        const siteSlug= this.$route.params.partner_slug
    },
}
</script>
<style scoped>

footer {
  margin-top: 10vw;
  margin-bottom: 1vw;
}
.wrapper {
  border: 1px solid black;
  border-radius: 3em;
  color: black;
  padding: 20px;
  margin-left: 20px;
  margin-right: 20px;

  position: sticky;
  z-index: 2;
  background: white;

  margin-top: 2em;

  display: flex;
}

.logo-section {
  display: flex; flex-direction: column; width: 30%;
}

ul,
.icon {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

ul {
  margin-left: auto;
}

.icon {
  margin-left: 1em;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

img {
  height: 5rem;
  object-fit: contain;
}

.text-small {
  font-size: 0.9rem;
  padding-top: 2rem;

  margin-left: 1em;

  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.39px;
  color: #000;
}

.align-right {
  margin-left: auto;
}

.align-right_links {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.link-row {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

li {
  padding-left: 3em;

  font-family: 'Space Mono', monospace;
  font-size: 0.8rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.13;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000;
}

li:first-child {
  padding-left: 0;
}

@media (min-width: 431px) {
  .mobile {
    display: none;
  }
}

@media (min-width: 431px) and (max-width: 1024px){

  .wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .icon {
    margin-right: 0;
    margin-left: 0;
    height: 2rem;
    flex-direction: column;
    justify-content: center;
  }

  .text-small {
    font-size: 0.8em;
    margin-left: 0;
  }

  li {
    font-size: 0.7em;
    padding-right: 1em;
  }

}

@media (max-width: 430px){
  footer {
    margin-top: 0;
    margin-bottom: 1vw;
    padding: 0 1em;
  }

  .wrapper {
    margin-left: 0;
    margin-right: 0;
    flex-direction: column;
  }

  .mobile {
    display: block;
  }

  .logo-section {
    width: 100%;
  }

  .icon {
    margin-left: 0;
  }

  .text-small {
    display: none;
  }

  .align-right {
    display: none;
  }
}

@media (min-width: 1025px){
  .text-small {
    font-size: 1.3vw;
  }

  img {
    height: 3vw;
    margin-left: -6vw;
  }

  li {
    font-size: 1vw;
  }
}
</style>
