import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'


export default function(store) {
  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

  router.afterEach(() => {
  return store.dispatch})

  router.afterEach(() => window.scrollTo(0,0))

  return router
}
