export default function(client) {
  const orderClient = client.order()

  const state = {
    order: null,
    paymentIntent: null,
    cardPreview: {}
  }

  const actions = {
    async create({ commit }, slug) {
      const paymentIntent = await orderClient.create(slug)
      commit('setPaymentIntent', paymentIntent)
      return paymentIntent
    },
    async update({ commit }, order) {
      const savedOrder = await orderClient.update(order)
      commit('setOrder', savedOrder)
      return savedOrder
    },
    async complete({ commit }, order) {
      const savedOrder = await orderClient.complete(order)
      commit('setOrder', savedOrder)
      return savedOrder
    },
    async saveCardPreview({ commit }, cardPreview) {
      commit('setCardPreview', cardPreview)
      return cardPreview
    }
  }

  const mutations = {
    setPaymentIntent(state, paymentIntent) {
      state.paymentIntent = paymentIntent
    },
    setOrder(state, order) {
      state.order = order
    },
    setCardPreview(state, cardPreview) {
      state.cardPreview = cardPreview
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
  }
}
