import pollockPrint1 from '@/assets/pollock/pollock-1.webp'
import pollockPrint2 from '@/assets/pollock/pollock-2.webp'
import pollockPrint3 from '@/assets/pollock/pollock-3.webp'
import pollockPrint4 from '@/assets/pollock/pollock-4.webp'
import pollockPrintSetMockup1 from '@/assets/pollock/pollock-set-transparent.webp'

const series1ReleaseDate = "Release July 2023"
const series2Title = "Series 2 - "
const series2ReleaseDate = null
const series1Title = "Series 1 - "
const imagePlaceholder = "https://placehold.co/1000x1250"
const titleBase = "Jackson Pollock Studio "

const allListings = [
  {
    certified: false,
    isSet: false,
    image: pollockPrint1,
    series: 1,
    seriesTitle: series1Title,
    date: series1ReleaseDate,
    title: titleBase + "01",
    description: "In Jackson Pollock Studio 01, you can immediately perceive Pollock's quintessential 'drip' technique, the chaotic beauty of lines and colors converging, diverging, and dancing across the canvas in this artifact.  This piece carries forward Pollock's legacy of creating art that captures raw emotion and unadulterated expression, rather than recreating physical forms.",
    url: "/details/jackson-pollock-studio-01"
  },
  {
    certified: false,
    isSet: false,
    image: pollockPrint2,
    series: 1,
    seriesTitle: series1Title,
    date: series1ReleaseDate,
    title: titleBase + "02",
    description: "This artifact collection piece from the studio floor is marked by its powerful spillover of Jackson Pollock's 1952 masterpiece, Convergence. An outsider amongst the artist's so-called black paintings.",
    url: "/details/jackson-pollock-studio-02"
  },
  {
    certified: false,
    isSet: false,
    image: pollockPrint3,
    series: 1,
    seriesTitle: series1Title,
    date: series1ReleaseDate,
    title: titleBase + "03",
    description: "Driven by a desire for people to look beyond subject and appreciate the work as pure painting, Jackson Pollock began to name his canvases by number. From beyond the edges of No. 3 (1950), we reveal the next perspective. What do you see?",
    url:  "/details/jackson-pollock-studio-03"
  },
  {
    certified: false,
    isSet: false,
    image: pollockPrint4,
    series: 1,
    seriesTitle: series1Title,
    date: series1ReleaseDate,
    title: titleBase + "04",
    description: "From beyond the edge of 'Blue Poles', Jackson Pollock Studio 04 features Pollock's ubiquitous drips and splatters from one of Pollock's most iconic and controversial works.",
    url: "/details/jackson-pollock-studio-04"
  },
  {
    certified: false,
    isSet: true,
    image: pollockPrintSetMockup1,
    series: 1,
    seriesTitle: series1Title,
    date: series1ReleaseDate,
    title: "Jackson Pollock Studio Set 1",
    description: "Presenting the Jackson Pollock Studio Series 1 Set—a timeless collection for the discerning art collector, an opportunity to fully immerse in the groundbreaking universe of one of the greatest Abstract Expressionists of our time. This ensemble brings together four remarkable artifact pieces—Jackson Pollock Studio 01, Studio 02, Studio 03, and Studio 04, each a striking exploration of Pollock's revolutionary techniques and evocative themes.",
    url: "/details/jackson-pollock-studio-set-1"
  },

  //Series 2 start

  // {
  //   certified: false,
  //   image: imagePlaceholder,
  //   series: 2,
  //   seriesTitle: series2Title,
  //   date: series1ReleaseDate,
  //   title: titleBase + "05",
  //   description: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat.",
  //   url: "#"
  // },
  // {
  //   certified: false,
  //   image: imagePlaceholder,
  //   series: 2,
  //   seriesTitle: series2Title,
  //   date: series2ReleaseDate,
  //   title: titleBase + "06",
  //   description: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat. Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat.",
  //   url: "#"
  // },
  // {
  //   certified: false,
  //   image: imagePlaceholder,
  //   series: 2,
  //   seriesTitle: series2Title,
  //   date: series2ReleaseDate,
  //   title: titleBase + "07",
  //   description: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat.",
  //   url:  "#"
  // },
  // {
  //   certified: false,
  //   image: imagePlaceholder,
  //   series: 2,
  //   seriesTitle: series2Title,
  //   date: series2ReleaseDate,
  //   title: titleBase + "08",
  //   description: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat.",
  //   url: "#"
  // },
  // {
  //   certified: false,
  //   image: imagePlaceholder,
  //   series: 2,
  //   seriesTitle: series2Title,
  //   date: series2ReleaseDate,
  //   title: "Jackson Pollock Studio Set 2",
  //   description: "Dolor sit amet, consectetur adipiscing elit, sed do eiusmod Eiusmod tempor incididunt ut labore et dolore magna aliqua quis nostrud exercitation ullamco laboris nisi commodo consequat.",
  //   url: "#"
  // }
]

export default function() {
  const state = {
    selectedSeries: null,
    selectedListing: allListings
  }

  const actions = {
    async selectSeries({ commit }, selectedSeries) {
      const selectedListing = selectedSeries
        ? allListings.filter(i => i.series == selectedSeries)
        : allListings


      commit('setSelectedSeries', selectedSeries)
      commit('setSelectedListing', selectedListing)
    }
  }

  const mutations = {
    setSelectedSeries(state, selectedSeries) {
      state.selectedSeries = selectedSeries
    },
    setSelectedListing(state, selectedListing) {
      state.selectedListing = selectedListing
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
  }
}
