<template>
  <nav>
    <a class="logo-link icon" :href="logoDestination()">
      <img :src="siteDetail.logo" alt="Logo">
    </a>
    <div class="links">
      <div v-for="(item, key) in headerLinks" :key="key">
        <nav-link :link="item.full_url" :name="item.title" :target="item.target" />
      </div>
      <div v-if="siteDetail.show_buy_link" class="line" />
      <nav-link v-if="siteDetail.show_buy_link" :link="siteDetail.buy_link" name="Buy" />
    </div>
    <div class="menu-button" @click="openNav">menu</div>
    <div id="mySidenav" class="sidenav">

      <a href="#" class="closebtn" @click="closeNav">&times;</a>
      <div v-for="(item, key) in headerLinks" :key="key">
        <nav-link :link="item.full_url" :name="item.title" @click="closeNav" />
      </div>
      <div v-if="siteDetail.show_buy_link">
        <nav-link :link="siteDetail.buy_link" name="Buy" />
      </div>
    </div>
  </nav>
</template>
<script>
import NavLink from './NavLink.vue'
import { mapState } from 'vuex'

export default {
  components: { NavLink },
  props: ['logo'],
  computed: {
     ...mapState({
        siteDetail: state => state.nav.siteDetail
     }),
     headerLinks() {
      return this.siteDetail.headerLinks
    },
  },
    async created() {
        const siteSlug= this.$route.params.partner_slug
        await this.$store.dispatch('nav/loadSiteDetail', siteSlug)
    },
  methods: {
    openNav() {
      document.getElementById("mySidenav").style.width = "250px"
    },
    closeNav() {
      document.getElementById("mySidenav").style.width = "0"
    },
    logoDestination() {
      return `https://iconicmoments.co`
    }
  }
}

</script>


<style lang="scss" scoped>

nav {
  height: 4rem;
  width: calc(100% - 3rem);
  background-color: white;

  position: fixed;
  z-index: 10;
  box-shadow: 1.5px 1.5px 4px -1px rgba(0,0,0,0.3);

  padding: 0 .75em;
  border-radius: 70px;
  border: solid 0.5px #979797;

  font-family: 'Space Mono', monospace;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.13;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000;

  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  margin: -($top-nav-spacer - 45) 1rem 0.5rem 1rem;
  @include on-laptop {
    margin-top: -($top-nav-spacer - 10)
  }
}

ul {
  height: 100%;
  align-items: center;
}

ul,
.icon {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 210px;
}

.icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  list-style: none;
  padding: 0;
  margin: 0;
  margin-right: 0;
  margin-left: 0;
  padding-left: 1em;
}

.links {
  display: flex;
  align-items: center;
}

.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  padding-right: 20px
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.line {
  width: 2px;
  height: 16px;
  margin: 5px 10px 5px 0;
  color:black;
  background-color: black;
}
@media (min-width: 711px) {
  nav li {
    padding-left: 1.15em;
    padding-right: 1.15em;
    white-space: nowrap;
  }
  nav li:last-child {
    padding-right: 2em;
  }
  .sidenav {
    display: none;
  }
  .menu-button {
    display: none;
  }
  img {
    height: 44px;
    object-fit: fill;
  }
}

@media (max-width: 710px) {
  nav {
    height: 3.5rem;
  }
  .sidenav a {
    padding: 8px 0;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  .links {
    display: none;
  }

  .menu-button {
    display: flex;
    cursor: pointer;
  }

  .icon {
    margin-right: unset;
    width: 200px;
  }
  img {
    height: 44px;
    object-fit: fill;
  }
}
</style>
