import { createStore } from 'vuex'

export default function initStore(modules) {
  const store = createStore({ modules })
  setupStoreSubscriptions(store)
  return store
}

function setupStoreSubscriptions(store) {
  store.subscribe((action, _state) => {
    if(action.type == 'auth/setAccountState') {
      store.dispatch('auth/getProofByDrop')
    }
  })
}
