import { createElementsFromHTML }  from '../../helpers.js'

export default function(client) {
    const siteClient = client.site()

    const state = {
      siteDetail: {}
    }

const actions = {
    async loadSiteDetail({ commit }, slug) {
      if(state.siteDetail.title == slug) {
        return state.siteDetail
      }

      commit('setSiteDetail', {})

      let siteDetail = await siteClient.getSite(slug)

      if(siteDetail.links) {
        siteDetail.headerLinks = siteDetail.links.filter(link => link.show_in_header)
        siteDetail.footerLinks = siteDetail.links.filter(link => link.show_in_footer)
      }
      
      const head = document.getElementsByTagName("head")[0]
      if(siteDetail.tracking_code) {
        const trackingCode = createElementsFromHTML(siteDetail.tracking_code)
        for (let tc of trackingCode) {
          head.append(tc)
        }
      }

      commit('setSiteDetail', siteDetail)
      return siteDetail
    }
}

const mutations = {
    setSiteDetail(state, siteDetail) {
        state.siteDetail = siteDetail
      }
}

const getters = {
}

return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
}
