export default function(client) {
  const walletClient = client.wallet()
  const claimClient = client.claim()

  const state = {
    order: null,
    claimInfo: {},
    claimMessage: "",
    checkingClaim: false
  }

  const actions = {
    async getClaimInfo({ commit }, partnerSlug) {      
      const claimInfo = await claimClient.claimInfo(partnerSlug)
      const usDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });
      claimInfo.countries.forEach(async country => {
        country.shippingEthFee = await walletClient.formatWei(country.fee)
        country.shippingUSDFee = usDollar.format(country.usd_fee)
        country.shippingFeeStripe = country.usd_fee * 100
      })
      claimInfo.baseClaimEthFee = await walletClient.formatWei(claimInfo.base_claim_fee)
      claimInfo.baseClaimUSDFee = usDollar.format(claimInfo.base_claim_fee_usd)
      claimInfo.baseClaimFeeStripe = claimInfo.base_claim_fee_usd * 100
      commit('setClaimInfo', claimInfo)
      return claimInfo
    },
    async checkClaim({ commit }, {partnerSlug, tokenId}) {
      if(!tokenId) {
        return
      }
      
      commit('setCheckingClaim', true)
      commit('setClaimMessage', "checking...")
      const isClaimed = await claimClient.isTokenClaimed(partnerSlug, tokenId)
      commit('setCheckingClaim', false)
      const claimMessage = isClaimed
        ? `Rewards for ${tokenId} are available!`
        : `Rewards for ${tokenId} are unavailable.`
      commit('setClaimMessage', claimMessage)
    },
    async getAvailableClaims({commit}, {partnerSlug, ethAddress}) {
      const availableClaims = await claimClient.availableClaims(partnerSlug, ethAddress)
      commit('setAvailableClaims', availableClaims)
      return availableClaims
    },
    async initiateBurnClaims(_store, {tokenIds, baseFee, shippingFee, contractAddress}) {
      return await walletClient.burnClaims(tokenIds, baseFee, shippingFee,contractAddress)
    },
    async initiatePayment(_store, {claimCount, baseFee, shippingFee, escrowWalletAddress}) {
      return await walletClient.sendPayment(claimCount, baseFee, shippingFee, escrowWalletAddress)
    },
    async initiatePaymentUSD({ commit }, {partnerSlug, amount, tokenIds}) {
      console.log('initiatePaymentUSD')
      console.log('partnerSlug', partnerSlug)
      console.log('amount', amount)
      console.log('tokenIds', tokenIds)
      const paymentIntent = await claimClient.claimPayment(partnerSlug, {amount, tokenIds})
      return paymentIntent
    },
    async claimTokens(_store, {partnerSlug, claimInfo}) {
      const claimResult = await claimClient.claimTokens(partnerSlug, claimInfo)
      return claimResult
    }
  }

  const mutations = {
    setNonce(state, nonce) {
      state.nonce = nonce
    },
    setClaimInfo(state, claimInfo) {
      state.claimInfo = claimInfo
    },
    setCheckingClaim(state, checkingClaim) {
      state.checkingClaim = checkingClaim
    },
    setClaimMessage(state, claimMessage) {
      state.claimMessage = claimMessage
    },
    setAvailableClaims(state, availableClaims) {
      state.availableClaims = availableClaims
    }
  }

  const getters = {
  }

  return {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
  }
}
